<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            Total
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ totalTransactions || 0 }} |
            {{ helpers.formatAmount(totalTransactionsAmount) || 0 }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            Successful
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ successfulTransactions || 0 }} |
            {{ helpers.formatAmount(successfulTransactionsAmount) || 0 }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            App Commission
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ totalCommissions || 0 }} |
            {{ helpers.formatAmount(commissionAmount) || 0 }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            Failed
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ failedTransactions || 0 }} |
            {{ helpers.formatAmount(failedTransactionsAmount) || 0 }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar class="primary--text text-h6" flat>
            All Transactions
          </v-toolbar>
          <search-and-filter :placeholder="placeholder"></search-and-filter>
          <v-data-table
            class="row-pointer"
            hide-default-footer
            :headers="headers"
            :items="transactions"
            :loading="loadTransactions"
            :items-per-page="pagination.limit"
            @click:row="showDetails"
          >
            <template v-slot:item.numbers="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:item.amount="{ item }">
              {{ helpers.formatAmount(item.amount) }}
            </template>
            <template v-slot:item.commission="{ item }">
              {{ helpers.formatAmount(item.commission) }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ helpers.formatDate(item.createdAt) }}
            </template>
            <template v-slot:item.reference="{ item }">
              {{ item.reference || item.requestRef }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div
      v-if="pagination.total > pagination.limit"
      class="my-4 d-flex justify-end"
    >
      <v-pagination
        v-model="pagination.page"
        :length="Math.ceil(pagination.total / pagination.limit)"
        :total-visible="pagination.total < 3 ? 2 : 6"
        @input="getNext"
      ></v-pagination>
    </div>
    <transaction-details :transaction="transaction" ref="transactionDetails" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Helpers from "../../utils/helpers";
import searchAndFilter from "../../components/searchAndFilter.vue";
import TransactionDetails from "@/components/transactions/TransactionDetails.vue";
export default {
  name: "TransactionPage",
  components: {
    searchAndFilter,
    TransactionDetails,
  },
  data() {
    return {
      helpers: new Helpers(),
      loading: false,
      loadTransactions: false,
      headers: [
        { text: "#", value: "numbers", sortable: false, orderable: false },
        { text: "Amount", value: "amount" },
        { text: "App Commission", value: "commission" },
        { text: "Reference", value: "reference" },
        { text: "Type", value: "type" },
        { text: "Status", value: "status" },
        { text: "Activity", value: "activity" },
        { text: "Date", value: "createdAt" },
      ],
      transactions: [],
      pagination: {
        limit: 5,
        page: 1,
        total: 0,
      },
      totalTransactions: 0,
      failedTransactions: 0,
      successfulTransactions: 0,
      totalTransactionsAmount: 0,
      successfulTransactionsAmount: 0,
      failedTransactionsAmount: 0,
      commissionAmount: 0,
      totalCommissions: 0,
      placeholder: "search... reference, type, activity, status",
      transaction: {},
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      get_transactions: "transactions/get_transactions",
      get_summary: "transactions/get_summary",
    }),
    async getTransactions(filter = null, filtered = null) {
      let data = filter;
      if (!filtered) {
        data = `?page=${this.pagination.page}&limit=${this.pagination.limit}`;
        this.$root.$emit("filtered", false);
      } else {
        this.$root.$emit("filtered", true);
      }

      this.loadTransactions = true;
      try {
        const response = await this.get_transactions(data);
        this.transactions = response.data.transactions;
        this.pagination.total = response.data.itemCount;
        this.pagination.page = response.data.currentPage;
        this.loadTransactions = false;
      } catch (e) {
        this.loadTransactions = false;
      }
    },
    async nextPage(filter, filtered) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      Promise.all([
        this.getSummary(filter),
        this.getTransactions(filter, filtered),
      ]);
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
    },
    async getSummary(filter = null) {
      try {
        const response = await this.get_summary(filter ? filter : "?");
        const data = response.data;
        this.totalTransactions = data.totalTransactions;
        this.successfulTransactions = data.successfulTransactions;
        this.failedTransactions = data.failedTransactions;
        this.totalTransactionsAmount = data.totalTransactionsAmount;
        this.successfulTransactionsAmount = data.successfulTransactionsAmount;
        this.failedTransactionsAmount = data.failedTransactionsAmount;
        this.commissionAmount = data.commissionAmount;
        this.totalCommissions = data.totalCommissions;
      } catch (e) {
        this.helpers.logConsole(e);
      }
    },
    showDetails(row) {
      this.transaction = Object.assign({}, row);
      this.$refs.transactionDetails.openDialog();
    },
    getNext() {
      this.$root.$emit("paginate", this.pagination);
    },
  },
  created() {
    this.getTransactions();
    this.getSummary();
  },
  mounted() {
    this.$root.$on("filter-transactions", (data, filtered) => {
      const { pagination } = data;
      this.pagination = Object.assign({}, pagination);
      const filter = `?page=${pagination.page}&limit=${pagination.limit}&search=${data.search}&start_date=${data.start_date}&end_date=${data.end_date}`;
      this.nextPage(filter, filtered);
    });
  },
};
</script>

<style scoped></style>
