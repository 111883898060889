<template>
  <div>
    <v-dialog
      :content-class="$vuetify.breakpoint.mdAndUp ? 'my-custom-dialog' : ''"
      v-model="dialog"
      max-width="500"
    >
      <v-card class="pa-4 text-left">
        <v-card-title class="pa-0">Transaction Details</v-card-title>
        <v-divider></v-divider>
        <div class="mt-3">
          <span>Transaction Reference: </span>
          <strong
            @click="copyToClipboard('trnRef', 'copied')"
            class="cursor-pointer"
          >
            {{ transaction.reference || transaction.requestRef }}
            <input
              id="trnRef"
              :value="transaction.reference || transaction.requestRef"
              readonly
              class="hide"
            />
            <v-icon size="18" color="primary" title="copy">
              mdi-content-copy
            </v-icon>
            <small id="copied" class="error--text d-none">copied!</small>
          </strong>
        </div>
        <div>
          <span>Transaction Type: </span>
          <strong>{{ transaction.type }}</strong>
        </div>
        <div>
          <span>Amount: </span>
          <strong>{{ helpers.formatAmount(transaction.amount) }}</strong>
        </div>
        <div>
          <span>Balance: </span>
          <strong>{{ helpers.formatAmount(transaction.balance) }}</strong>
        </div>
        <div>
          <span>Activity: </span>
          <strong>{{ transaction.activity }}</strong>
        </div>
        <div v-if="!transaction.activity.toLowerCase().includes('bank')">
          <span>Service Name</span>
          <strong>
            {{ serviceType() + variationCode() }}
          </strong>
        </div>
        <div v-if="!bank_transfer">
          <span>{{ unique_element_title }}: </span>
          <strong>{{ unique_element }}</strong>
        </div>
        <div
          v-if="
            transaction.activity &&
            transaction.activity.indexOf('Electricity') > -1
          "
        >
          <span>Token: </span>
          <strong
            class="cursor-pointer"
            @click="copyToClipboard('token', 'copiedToken')"
          >
            {{ payload.resp.token }}
            <input
              id="token"
              class="hide"
              readonly
              :value="payload.resp.token || ''"
            />
            <v-icon size="18" color="primary" title="copy">
              mdi-content-copy
            </v-icon>
            <small id="copiedToken" class="error--text d-none">copied!</small>
          </strong>
        </div>
        <div
          v-if="
            transaction.activity &&
            transaction.activity.indexOf('Electricity') > -1
          "
        >
          <span>Units: </span>
          <strong>{{ payload.resp.units }}</strong>
        </div>
        <div v-if="bank_transfer">
          <div v-if="transaction.type === 'Debit'">
            <div>
              <span>Recipient Account Name: </span>
              <strong>{{ recipient.account_name }}</strong>
            </div>
            <div>
              <span>Recipient Account Number: </span>
              <strong>{{ recipient.account_number }}</strong>
            </div>
            <div>
              <span>Recipient Bank: </span>
              <strong>{{ recipient.bank_name }}</strong>
            </div>
          </div>
          <div v-if="transaction.type === 'Credit'">
            <div>
              <span>Sender Account Name: </span>
              <strong>{{ sender.account_name }}</strong>
            </div>
            <div>
              <span>Sender Account Number: </span>
              <strong>{{ sender.account_number }}</strong>
            </div>
            <div>
              <span>Sender Bank: </span>
              <strong>{{ sender.bank_name }}</strong>
            </div>
          </div>
        </div>
        <div>
          <span>Customer Name: </span>
          <strong>
            {{ customer.account_name }}
          </strong>
        </div>
        <div>
          <span>Customer Account Number: </span>
          <strong>
            {{ customer.account_number }}
          </strong>
        </div>
        <div>
          <span>Status: </span>
          <strong>
            {{ transaction.status }}
          </strong>
        </div>
        <div>
          <span>Transaction Date: </span>
          <strong>
            {{ helpers.formatDate(transaction.createdAt) }}
          </strong>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed class="close mr-3" @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Helpers from "@/utils/helpers";
export default {
  props: {
    transaction: {
      default: Object,
      type: Object,
    },
  },
  name: "TransactionDetails",
  data() {
    return {
      dialog: false,
      helpers: new Helpers(),
    };
  },
  computed: {
    payload() {
      const payload = this.transaction?.payload;
      return typeof payload === "string" ? JSON.parse(payload) : payload;
    },
    unique_element() {
      const data = this.payload;
      return data?.resp
        ? data?.resp?.content?.transactions?.unique_element
        : data?.content?.transactions?.unique_element;
    },
    unique_element_title() {
      const activity = this.transaction?.activity;
      return activity?.indexOf("Electricity") > -1
        ? "Meter Number"
        : activity?.indexOf("Cable") > -1
        ? "IUC Number"
        : "Phone Number";
    },
    customer() {
      return {
        account_name: this.transaction?.User?.fullName || "",
        account_number:
          this.transaction?.User?.AccountNumber?.account_number || "",
      };
    },
    recipient() {
      const data = this.payload;
      return {
        account_name: data?.recipientName || data?.beneficiaryName,
        account_number:
          data?.recipientAccountNumber || data?.beneficiaryAccount,
        bank_name: data?.receivingBank,
      };
    },
    sender() {
      const data = this.payload;
      return {
        account_name: data?.senderName,
        account_number: data?.senderAccountNumber,
        bank_name: data?.senderBank,
      };
    },
    bank_transfer() {
      return this.transaction?.activity?.indexOf("Bank") > -1;
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    hideCopied(elem) {
      const timer = setTimeout(() => {
        elem.classList.add("d-none");
        if (timer) {
          clearTimeout(timer);
        }
      }, 1500);
    },
    copyToClipboard(elemToCopy, elemMsg) {
      document.getElementById(elemToCopy).select();
      document.execCommand("copy");
      const copyMsg = document.getElementById(elemMsg);
      copyMsg.classList.remove("d-none");
      this.hideCopied(copyMsg);
    },
    serviceType() {
      return this.payload?.resp?.content?.transactions?.product_name;
    },
    variationCode() {
      return this.payload.variation_code
        ? ` (${this.payload.variation_code})`
        : "";
    },
  },
};
</script>

<style scoped>
>>> .my-custom-dialog {
  position: absolute;
  right: 0;
}
</style>
